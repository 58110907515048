jQuery(function ($) {
  $(document).ready(function () {
    const $itemsWithMenu = $('.menu-item-has-children')

    $itemsWithMenu.each(function () {
      const $item = $(this)
      let fadeOutTimeout

      $item.on('mouseenter', function () {
        if (window.innerWidth > 640) {
          $item.find('.sub-menu').slideDown('fast')
          clearTimeout(fadeOutTimeout)
        }
      })

      $item.on('mouseleave', function () {
        if (window.innerWidth > 640) {
          const $submenu = $item.find('.sub-menu')
          fadeOutTimeout = setTimeout(function () {
            $submenu.slideUp('fast')
          }, 500)
        }
      })

      $item.on('click', function (e) {
        if ($(e.target).closest('.sub-menu').length) {
          return
        }

        if (window.innerWidth <= 640) {
          e.preventDefault()
          const $submenu = $item.find('.sub-menu')
          $submenu.slideToggle()
        }
      })
    })

    const $menuOpenButton = $('.navbar-toggle--open')
    const $menuCloseButton = $('.navbar-toggle--close')
    $menuOpenButton.on('click', function () {
      $('.navbar').fadeIn()
    })
    $menuCloseButton.on('click', function () {
      $('.navbar').fadeOut(function () {
        $(this).css('display', '')
      })
    })

    let currentIndex = 0
    const slides = $('.home-slider--item')
    const transitionDuration = 2000
    const slideInterval = 8000

    if (slides.length) {
      slides.not(slides.eq(currentIndex)).css('opacity', 0)
      slides.eq(currentIndex).css('opacity', 1)

      setInterval(function () {
        const nextIndex = (currentIndex + 1) % slides.length

        slides.eq(currentIndex).animate({ opacity: 0 }, transitionDuration)

        slides.eq(nextIndex).animate({ opacity: 1 }, transitionDuration)

        currentIndex = nextIndex
      }, slideInterval)
    }
  })
})
